<script>
import axios from "axios";
import { Icon } from "@iconify/vue2";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;
/**
 * Widget Component
 */
export default {
  components: { Icon },
  data() {
    return {
      statData: [
        {
          endpoint: "reservation",
          title: "Toplam Rezervasyon Sayısı",
          icon: "lucide:calendar-check",
          value: null,
        },
        {
          endpoint: "agency",
          title: "Toplam Acenta Sayısı",
          icon: "lucide:store",
          value: null,
        },
        {
          endpoint: "customer",
          title: "Toplam Müşteri Sayısı",
          icon: "lucide:users",
          value: null,
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.statData.forEach((item) => {
        const api_url = `${process.env.VUE_APP_BASEURL}/${item.endpoint}`;

        axios
          .get(api_url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            item.value = response.data.meta.total;
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0" v-if="data.value">{{ data.value }}</h4>
              <div
                class="spinner-border"
                style="height: 1.35rem; width: 1.35rem"
                role="status"
                v-else
              >
                <span class="sr-only">Yükleniyor...</span>
              </div>
            </div>
            <div class="text-primary">
              <Icon :icon="data.icon" class="font-size-24" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
