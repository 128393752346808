<script>
import axios from "axios";
let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;
/**
 * Widget Component
 */
export default {
  data() {
    // line chart
    return {
      series: [
        {
          name: "Yapılan Rezervasyon Girişi",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        colors: ["#5d78ff"],
        grid: {
          row: {
            colors: ["#f3f6ff", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            ...Array.from({ length: 7 }, (_, i) => {
              const date = new Date();
              date.setDate(date.getDate() - i);
              return date.toLocaleDateString();
            }).reverse(),
          ],
        },
      },
    };
  },
  created() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      const date = new Date();
      const today = date.toLocaleDateString();
      const lastWeek = new Date(date.setDate(date.getDate() - 7))
        .toLocaleDateString()
        .split(".")
        .reverse()
        .join("-");
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/reservation?startDate=${lastWeek}&endDate=${today}&status=1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data.data;
          console.log(data);
          const series = [
            {
              name: "Yapılan Rezervasyon Girişi",
              data: [
                ...Array.from({ length: 7 }, (_, i) => {
                  const date = new Date();
                  date.setDate(date.getDate() - i);
                  return date.toLocaleDateString();
                })
                  .reverse()
                  .map((date) => {
                    const count = data.filter(
                      (reservation) => reservation.date === date
                    ).length;
                    return count;
                  }),
              ],
            },
          ];
          this.series = series;
        });
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="cart-title mb-4">Haftalık Rezervasyon Girişleri</h4>
      <div id="donut-chart" class="apex-charts">
        <apexchart
          class="apex-charts"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
